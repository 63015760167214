.color-primary {
	color: var(--theme-constrast-text-color);
	background-color: var(--theme-primary-color);
	border-color: var(--theme-primary-lighter-color);
}

.color-red {
	color: var(--theme-color-red-text);
	background-color: var(--theme-color-red-bg);
	border-color: var(--theme-color-red-border);
}

.color-green {
	color: var(--theme-color-green-text);
	background-color: var(--theme-color-green-bg);
	border-color: var(--theme-color-green-border);
}

.color-blue {
	color: var(--theme-color-blue-text);
	background-color: var(--theme-color-blue-bg);
	border-color: var(--theme-color-blue-border);
}

.color-orange {
	color: var(--theme-color-orange-text);
	background-color: var(--theme-color-orange-bg);
	border-color: var(--theme-color-orange-border);
}

.color-grey {
	color: var(--theme-color-grey-text);
	background-color: var(--theme-color-grey-bg);
	border-color: var(--theme-color-grey-border);
}

.color-purple {
	color: var(--theme-color-purple-text);
	background-color: var(--theme-color-purple-bg);
	border-color: var(--theme-color-purple-border);
}
