@use "sass:math";

// from https://github.com/twbs/bootstrap/blob/c198eb6319d63c5414dbe3036ad160bd78d4cf01/scss/_variables.scss
$spacer: 1rem !default;
$spacers: (
		0: 0,
		1: math.div($spacer, 4),
		2: math.div($spacer, 2),
		3: $spacer,
		4: $spacer * 1.5,
		5: $spacer * 3,
) !default;
$grid-columns: 12 !default;
$grid-gutter-width: 1.5rem !default;
$grid-row-columns: 6 !default;
$gutters: $spacers !default;
$container-padding-x: math.div($grid-gutter-width, 2) !default;
$include-column-box-sizing: true !default;

$variable-prefix: w- !default;

// from https://github.com/twbs/bootstrap/blob/232e392c9126fcb1dbe13c963e7edcdbd62cfeb8/scss/_variables.scss
$grid-breakpoints: (
	// X-Small devices (portrait phones, less than 576px)
		xs: 0,
	// Small devices (landscape phones, 576px and up)
		sm: 576px,
	// Medium devices (tablets, 768px and up)
		md: 768px,
	// Large devices (desktops, 992px and up)
		lg: 992px,
	// X-Large devices (large desktops, 1200px and up)
		xl: 1200px,
	// XX-Large devices (larger desktops, 1400px and up)
		xxl: 1400px
) !default;

// from https://stackoverflow.com/a/54594828
@function from-breakpoints-map($name, $breakpoints: $grid-breakpoints) {
	@if map-has-key($breakpoints, $name) {
		@return map-get($breakpoints, $name);
	} @else {
		@warn "Unfortunately, no value could be retrieved from `#{$name}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
	}
}

// from https://github.com/twbs/bootstrap/blob/3cf51c6ac95c4702efc632a7983019c71f77d4b3/scss/mixins/_breakpoints.scss

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
	@return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
	$min: from-breakpoints-map($name, $breakpoints);
	@return if($min != 0, $min, null);
}

// Maximum breakpoint width.
// The maximum value is reduced by 0.02px to work around the limitations of
// `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(md, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
	$max: from-breakpoints-map($name, $breakpoints);
	@return if($max and $max > 0, $max - .02, null);
}

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
	$n: index($breakpoint-names, $name);
	@if not $n {
		@error "breakpoint `#{$name}` not found in `#{$breakpoints}`";
	}
	@return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

//TODO rename to something clearer than down/up, like min/max?

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
	$max: breakpoint-max($name, $breakpoints);
	@if $max {
		@media (max-width: $max) {
			@content;
		}
	} @else {
		@content;
	}
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($lower, $breakpoints);
	$max: breakpoint-max($upper, $breakpoints);

	@if $min != null and $max != null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($lower, $breakpoints) {
			@content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($upper, $breakpoints) {
			@content;
		}
	}
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	$next: breakpoint-next($name, $breakpoints);
	$max: breakpoint-max($next);

	@if $min != null and $max != null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($name, $breakpoints) {
			@content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($next, $breakpoints) {
			@content;
		}
	}
}

// from https://github.com/twbs/bootstrap/blob/232e392c9126fcb1dbe13c963e7edcdbd62cfeb8/scss/mixins/_grid.scss
@mixin make-row($gutter: $grid-gutter-width) {
	--#{$variable-prefix}gutter-x: #{$gutter};
	--#{$variable-prefix}gutter-y: 0;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-top: calc(var(--#{$variable-prefix}gutter-y) * -1);
	margin-right: calc(var(--#{$variable-prefix}gutter-x) / -2);
	margin-left: calc(var(--#{$variable-prefix}gutter-x) / -2);
}

@mixin make-col-ready($gutter: $grid-gutter-width) {
	// Add box sizing if only the grid is loaded
	box-sizing: if(variable-exists(include-column-box-sizing) and $include-column-box-sizing, border-box, null);
	// Prevent columns from becoming too narrow when at smaller grid tiers by
	// always setting `width: 100%;`. This works because we set the width
	// later on to override this initial width.
	flex-shrink: 0;
	width: 100%;
	max-width: 100%; // Prevent `.col-auto`, `.col` (& responsive variants) from breaking out the grid
	padding-right: calc(var(--#{$variable-prefix}gutter-x) / 2); // stylelint-disable-line function-disallowed-list
	padding-left: calc(var(--#{$variable-prefix}gutter-x) / 2); // stylelint-disable-line function-disallowed-list
	margin-top: var(--#{$variable-prefix}gutter-y);
}

@mixin make-col($size, $columns: $grid-columns) {
	flex: 0 0 auto;
	width: percentage(math.div($size, $columns));
}

@mixin make-col-auto() {
	flex: 0 0 auto;
	width: auto;
}

@mixin make-col-offset($size, $columns: $grid-columns) {
	$num: math.div($size, $columns);
	margin-left: if($num == 0, 0, percentage($num));
}

// Row columns
//
// Specify on a parent element(e.g., .row) to force immediate children into NN
// numberof columns. Supports wrapping to new lines, but does not do a Masonry
// style grid.
@mixin row-cols($count) {
	> * {
		flex: 0 0 auto;
		width: math.div(100%, $count);
	}
}

// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
	@each $breakpoint in map-keys($breakpoints) {
		$infix: breakpoint-infix($breakpoint, $breakpoints);

		@include media-breakpoint-up($breakpoint, $breakpoints) {
			// Provide basic `.col-{bp}` classes for equal-width flexbox columns
			.col#{$infix} {
				flex: 1 0 0%; // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
			}

			.row-cols#{$infix}-auto > * {
				@include make-col-auto();
			}

			@if $grid-row-columns > 0 {
				@for $i from 1 through $grid-row-columns {
					.row-cols#{$infix}-#{$i} {
						@include row-cols($i);
					}
				}
			}

			.col#{$infix}-auto {
				@include make-col-auto();
			}

			@if $columns > 0 {
				@for $i from 1 through $columns {
					.col#{$infix}-#{$i} {
						@include make-col($i, $columns);
					}
				}

				// `$columns - 1` because offsetting by the width of an entire row isn't possible
				@for $i from 0 through ($columns - 1) {
					@if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
						.offset#{$infix}-#{$i} {
							@include make-col-offset($i, $columns);
						}
					}
				}
			}

			// Gutters
			//
			// Make use of `.g-*`, `.gx-*` or `.gy-*` utilities to change spacing between the columns.
			@each $key, $value in $gutters {
				.g#{$infix}-#{$key},
				.gx#{$infix}-#{$key} {
					--#{$variable-prefix}gutter-x: #{$value};
				}

				.g#{$infix}-#{$key},
				.gy#{$infix}-#{$key} {
					--#{$variable-prefix}gutter-y: #{$value};
				}
			}
		}
	}
}

// from https://github.com/twbs/bootstrap/blob/3cf51c6ac95c4702efc632a7983019c71f77d4b3/scss/_grid.scss

.row {
	@include make-row();

	> * {
		@include make-col-ready();
	}

	& + & {
		margin-top: 12px;
	}
}

@include make-grid-columns();
