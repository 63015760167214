@import "/src/assets/scss/helpers";

.TwoColumns {
	display: flex;
	flex-direction: row;

	> .Left {
		width: 50%;
	}

	> .Right {
		width: 50%;
	}

	&.size-60-40 {
		> .Left {
			width: 60%;
		}

		> .Right {
			width: 40%;
		}
	}
}
