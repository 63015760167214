// Use @include with the name of the mixin
// and @import the _helpers file at the top
@use "sass:math";
@import "shadow";
// TODO simplification to only extract the mixins?
@import "grid";

@mixin default-font {
	//font-family: Roboto, "Helvetica Neue", sans-serif;
	font-family: var(--base-general-font);
}

@mixin default-font-mono {
	//font-family: Consolas, Courier New, monospace;
	font-family: var(--base-monospace-font);
}

@mixin default-font-text {
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

@mixin dark-theme {
	.dark-theme & {
		@content;
	}
}

// normal / hover / active
@mixin redefine-color($baseColorVar, $hoverColorVar: $baseColorVar, $activeColorVar: $hoverColorVar) {
	color: var($baseColorVar);

	//TODO simplify into only 3
	--current-color: var(#{$baseColorVar});
	--current-text-effect-color: var(#{$hoverColorVar});
	--current-border-color: var(#{$hoverColorVar});
	--current-hover-color: var(#{$hoverColorVar});
	--current-active-color: var(#{$activeColorVar});
}

@mixin redefine-background($baseColorBackgroundVar, $hoverBackgroundColorVar: $baseColorBackgroundVar, $activeBackgroundColorVar: $hoverBackgroundColorVar) {
	background-color: var($baseColorBackgroundVar);

	//TODO simplify into only 3
	--current-background-color: var(#{$baseColorBackgroundVar});
	--current-background-effect-color: var(#{$hoverBackgroundColorVar});
	--current-background-hover-color: var(#{$hoverBackgroundColorVar});
	--current-background-active-color: var(#{$activeBackgroundColorVar});

	// could have used
	// https://blog.jim-nielsen.com/2021/css-relative-colors/
	// if there is a way to indicate the type of color modifications per theme + per area
}

@mixin custom-scrollbar {
	overflow: auto;

	&::-webkit-scrollbar {
		width: 18px;
	}

	&::-webkit-scrollbar-thumb {
		background: var(--theme-scrollbar-thumb-color);
		border-radius: 9px;
		border: 5px solid var(--current-background-color);
	}

	&::-webkit-scrollbar-thumb:hover {
		background: var(--theme-scrollbar-thumb-hover-color);
	}
}

//TODO also find a way to add the expected interactions from https://webaim.org/techniques/keyboard/#testing

/** 
 * For elements without regular focus behavior like span/div,
 * use the useRefForSpaceAndEnter to simulate those accessibility behaviors
 */
@mixin focusable {
	&:focus-visible {
		box-shadow: 0 0 3px 2px var(--theme-primary-color);
	}
}

@mixin focusable-within {
	&:focus-within {
		box-shadow: 0 0 3px 1px var(--theme-primary-color);
	}
}

@mixin hover {
	&:hover, &.hover {
		background-color: var(--current-background-hover-color, var(--theme-effect-hover-bg));
	}
}

@mixin active {
	&:active, &.active {
		background-color: var(--current-background-active-color, var(--theme-effect-active-bg));
	}
}

@mixin column-string-limit() {
	//flex: 1 1 auto; => otherwise the cell can be consumed by the column-expand
	overflow: hidden;
	// direct breakage instead of ...
	text-overflow: clip;
	white-space: nowrap;
}

@mixin column-expand() {
	flex: 1 1 auto;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin column-fix($width) {
	max-width: $width;
	flex-basis: $width;
}

@mixin column-fix-boolean() {
	@include column-fix(50px);
}

@mixin column-fix-icon() {
	@include column-fix(45px);
}

@mixin column-fix-date() {
	@include column-fix(100px);
}

@mixin column-fix-iso-datetime() {
	@include column-fix(230px);
}

@mixin shadow($elevation: 3) {
	@extend .elevation-z#{$elevation};
}

@mixin background-strip-moving($speed: 1) {
	background-color: var(--theme-effect-hover-bg);

	background: repeating-linear-gradient(
					-45deg,
					var(--theme-effect-hover-bg),
					var(--theme-effect-hover-bg) 10px,
					var(--current-background-color) 10px,
					var(--current-background-color) 20px
	);
	background-repeat: repeat;
	background-position: 0 0;
	//background-size: auto auto;
	background-size: 200% 200%;

	animation: gradient #{math.div(45, $speed)}s linear infinite;

	@keyframes gradient {
		0% {
			background-position: 100% 100%;
		}
		//from {
		//	background-position: 0 0;
		//}
		//to {
		//	background-position: 2500px 0;
		//}
	}
}

@mixin basic-page() {
	margin: 20px 30px 45px 30px;

	@include media-breakpoint-down(md){
		margin: 5px;
	}
}
