.TopNavTitle {
	display: flex;
	align-items: center;
	position: relative;

	padding: 4px 8px;
	margin-left: -3px;
	margin-right: 8px;
	border-radius: var(--base-border-radius);
	cursor: pointer;
	user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;

	//color: var(--theme-constrast-text-color);
	color: var(--current-color);

	&.hover, &:hover {
		background-color: var(--theme-effect-hover-bg);
	}

	&.active, &:active {
		background-color: var(--theme-effect-active-bg);
	}

	svg {
		fill: currentColor;
		width: 32px;
		height: 38px;
		display: inline-block;
	}

	.AppName {
		display: inline-block;

		margin-left: 11px;
		margin-right: 8px;

		font-size: 20px;
		font-weight: 500;
	}
}
