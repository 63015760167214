@import "/src/assets/scss/helpers";

.Panel {
	display: flex;
	align-items: center;
	
	padding: calc(1.5 * var(--base-padding)) calc(2 * var(--base-padding));
	border-width: 1px;
	border-style: solid;
	border-radius: var(--base-border-radius);

	> .icon {
		line-height: 0.5;

		> svg {
			height: 24px;
			width: 24px;
			margin-right: var(--base-margin-from-svg);
		}
	}

	&.size-small {
		padding: 5px var(--base-padding);//var(--base-padding); was too big for action bar
	}

	> .message {
		//padding-top: 14px;
	}
}
