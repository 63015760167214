.SideNavLink {
	display: flex;
	position: relative;

	padding: 4px 8px;
	margin-right: 8px;
	//border-top-right-radius: 18px;
	//border-bottom-right-radius: 18px;
	border-top-right-radius: var(--base-side-nav-link-height);
	border-bottom-right-radius: var(--base-side-nav-link-height);

	line-height: var(--base-side-nav-link-height);
	//font-weight: 500;
	font-size: 16px;
	cursor: pointer;
	user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;

	&:not(.disabled) {
		&.hover, &:hover {
			background-color: var(--theme-effect-hover-bg);
		}

		&.active, &:active {
			background-color: var(--theme-effect-active-bg);
		}
	}

	&.disabled {
		cursor: initial;
		color: var(--theme-side-nav-disabled-color, var(--theme-effect-disabled-color));
	}

	svg {
		font-size: 24px;

		align-self: flex-start;
		margin-top: 6px;
		flex: 0 0 24px;
	}

	.label {
		display: inline-block;
		flex: 1;
		vertical-align: middle;
	}

	// with icon
	svg + .label {
		margin-left: var(--base-margin-from-svg);
	}

	// with afterIcon
	.label + svg {
		margin-left: var(--base-margin-from-svg);
	}
	
	> .Badge {
		flex: 0 0 content;
		align-self: flex-start;
		margin-top: 7px;
		margin-left: var(--base-default-margin);
	}
}
