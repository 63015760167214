@import "../../../../assets/scss/helpers";

.GitHubPermissionToolingPage {
	.dynamic-response-panel {
		@include shadow(3);
		padding: calc(var(--base-padding) * 2);

		&.reloading {
			@include background-strip-moving();
		}

		.no-entries {

		}

		.response-title {
			font-size: 120%;
			font-weight: bold;
			margin-bottom: var(--base-default-margin);
		}

		.ListingPermissionTeamEntry + .ListingPermissionCollaboratorEntry {
			margin-top: var(--base-default-margin);
		}

		//.ListingPermissionTeamEntry, .ListingPermissionCollaboratorEntry {
		.ListingPermissionEntry {
			line-height: 30px;

			svg {
				font-size: 20px;
				vertical-align: middle;
			}

			//.ListingPermissionTeamName, .ListingPermissionCollaboratorName {
			.ListingPermissionEntryLabel {
				> svg {
					margin-right: var(--base-margin-from-svg);
				}

				.LinkButton, .TextButton {
					margin-left: var(--base-default-margin);
				}

				.ListingPermissionTeamSlug,
				.ListingPermissionTeamRole,
				.ListingPermissionCollaboratorLogin,
				.ListingPermissionCollaboratorRole,
				{
					margin-left: var(--base-default-margin);
				}

				.ListingName {
					margin-left: var(--base-default-margin);
				}

				.ListingPermission {
					//TODO to become a status label like for Jira
					text-transform: uppercase;
					font-weight: bold;
					min-width: 100px;
					display: inline-block;
				}
			}

			.SingleSelectGroup + .IconButton,
			.IconButton + .IconButton {
				margin-left: var(--base-two-margin);
			}

			.ListingPermissionCollaboratorName .ListingPermissionName {
				min-width: 100px;
				display: inline-block;
			}
		}
	}
}
