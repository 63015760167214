.ActionBar {
	display: flex;

	flex: 1;
	justify-content: space-between;

	> .LeftGroup {
		display: flex;
		align-items: center;
		margin-right: var(--base-default-margin);
	}

	> .RightGroup {
		display: flex;
		align-items: center;
		margin-left: var(--base-default-margin);
	}

	> .LeftGroup, > .RightGroup {
		//TODO use a mixin for horizontal alignment
		> * {
			//margin-left: var(--base-default-margin);
			margin-left: var(--base-two-margin);

			&:first-child {
				margin-left: 0;
			}
		}
		> svg {
			font-size: 24px;
		}
	}
}
