@import "../../../../assets/scss/helpers";

.ArtifactoryToolingPage {
	.dynamic-response-panel {
		@include shadow(3);
		padding: calc(var(--base-padding) * 2);

		.no-entries {

		}

		.response-title {
			font-size: 120%;
			font-weight: bold;
			margin-bottom: var(--base-default-margin);
		}

		.ListingArtifactEntry {
			svg {
				font-size: 20px;
				vertical-align: middle;
			}

			.ListingArtifactName {
				> svg {
					margin-right: var(--base-margin-from-svg);
				}

				.LinkButton {
					margin-left: var(--base-default-margin);
				}
			}

			ol {
				li {
					margin-left: var(--base-default-margin);
					line-height: 20px;

					&.was-deleted {
						code {
							text-decoration: line-through;
						}
					}

					.ListingArtifactVersion {
						margin-left: var(--base-margin-from-svg);
						min-width: 225px;
						display: inline-block;
					}

					.LinkButton, .TextButton {
						margin-left: var(--base-default-margin);
					}
				}

				& > li {
					margin-top: var(--base-default-margin);
				}

				li + li {
					margin-top: var(--base-default-margin);
				}
			}
		}

		.ListingArtifactEntry + .ListingArtifactEntry {
			margin-top: var(--base-two-margin);
		}
	}
}
