@import "/src/assets/scss/helpers";

.TablePaginationCursor {
	display: flex;

	& > * + * {
		margin-left: var(--base-default-margin);
	}

	& > .page-info {
		display: inline-block;
		line-height: 36px;
		padding: 0 8px;

		& > .PageNo {
			display: inline-block;

			& > input {
				width: 45px;
				padding: 6px 9px;
				font-size: 14px;
				font-weight: 400;
				color: #212529;
				background-color: var(--current-background-color);
				border: 1px solid #ced4da;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				border-radius: 4px;
				transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
			}

			& > .input-inactive {
				@include focusable;
				@include hover;
				@include active;

				cursor: pointer;
				padding: 6px 6px;
				margin-left: -6px;
				margin-right: -6px;
			}

			& > .input-disabled {
				color: var(--theme-less-important-text-color);
			}
		}

		& > .page-sep {
			color: var(--theme-less-important-text-color);
		}

		& > .page-max {
			color: var(--theme-less-important-text-color);
		}
	}

	& > .PageSize {
		margin-left: var(--base-two-margin);
		display: inline-block;

		& > .item-per-page-label {
			line-height: 36px;
			padding-top: 1px;
			margin-right: var(--base-default-margin);

			color: var(--theme-less-important-text-color);
		}

		& > .DropdownMenu2 {
			display: inline-block;

			& > .TextButton {
				padding: 0 4px 0 12px;
				line-height: 34px;
				// to reduce the space after the arrow to the right border
				justify-content: space-between;
			}
		}
	}
}
	
