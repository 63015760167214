@import "/src/assets/scss/helpers";

.Emails3Page {
	.Table2.fluid {
		tbody {
			tr {
				td {
					
					&.cell-sender {
						display: flex;
					}
					
					&.cell-subject {
						flex: 1 1 auto;
						height: auto;
						display: flex;
						padding-right: 10px;
						min-width: 0;

						.email-subject-body {
							flex: 1 1 auto;
							overflow: hidden;
							display: block;
							
							.email-subject-body2{
								display :flex;
								align-items: baseline;
								overflow: hidden;
								white-space: nowrap;
								
								.email-subject {
									//TODO also add overflow in case the subject is really long
									flex-shrink: 1;
									align-items: baseline;
									display: inline-flex;
									
									font-weight: bold;
									line-height: 20px;
									overflow: hidden;
									
									.email-subject2 {
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
										line-height: 20px;
									}
								}
								
								.email-body-span{
									overflow: hidden;
									text-overflow: ellipsis;;
									white-space: nowrap;
									flex-basis: 0;
									flex-grow: 1;
									flex-shrink: 1;
									text-align: left;
									
									.sb-separator {
										font-size: .875rem;
										letter-spacing: .2px;
									}
								}
							}

						}
					}
					&.cell-labels {
						@include default-font-mono;
					}
					&.cell-tags {
						@include default-font-mono;
						overflow: hidden;
						text-overflow: ellipsis;
						padding-right: 5px;
					}
					&.cell-source {
						@include default-font-mono;
						overflow: hidden;
						text-overflow: ellipsis;
						padding-right: 5px;
					}
				}
			}
		}
	}
}
