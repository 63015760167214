@import "/src/assets/scss/helpers";

.PageContent {
	@include basic-page;

	&.with-padding {
		padding: 18px;
	}
	
	&.with-shadow {
		@include shadow(3);
	}
}
