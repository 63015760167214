@import "/src/assets/scss/helpers";

tr.Row {
	> td {
		padding: 12px!important;
		vertical-align: middle;
	
		//position: relative;
		//padding: calc(1.5 * var(--base-padding)) calc(2 * var(--base-padding));

		> .row-content {
			display: flex;
			align-items: center;
			
			padding: 12px;
			border-width: 1px;
			border-style: solid;
			border-radius: var(--base-border-radius);

			& > .icon {
				line-height: 0.5;

				> svg {
					height: 24px;
					width: 24px;
					margin-right: var(--base-margin-from-svg);
				}
			}

			& > .message {

			}
		}
	}
}
