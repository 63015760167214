@import "../../../assets/scss/helpers";

.TopNav {
	display: flex;
	//flex: 0;
	flex-direction: row;
	height: var(--base-nav-top-height);
	z-index: var(--base-z-top-nav);

	@include media-breakpoint-down(md){
		overflow-x: auto;
	}

	padding-left: 15px;
	padding-right: 15px;

	//color: var(--theme-constrast-text-color);

	@include redefine-color(--theme-constrast-text-color);
	@include redefine-background(--theme-primary-color, --theme-effect-hover-bg, --theme-effect-active-bg);
	
	//TODO use the working version
	//@include define-background(--theme-primary-color);
	
	
	//background-color: var(--theme-primary-color);
	//--current-background-color: var(--theme-primary-color);
	
	//// laptop
	//@media (min-width: 992px) {
	//	.TopNavReactiveButton {
	//		display: none;
	//	}
	//}
	//// phone
	//@media (max-width: 991px) {
	//	.TopLeftCorner{
	//		display: none;
	//	}
	//	.TopNavTitle{
	//		display: none;
	//	}
	//	.TopNavReactiveButton {
	//		display: block;
	//	}
	//}

	.TopNavReactiveButton {
		display: block;

		@include media-breakpoint-up(md){
			display: none;
		}
	}

	.TopLeftCorner {
		display: flex;

		@include media-breakpoint-down(md){
			display: none;
		}
		
		flex: 0 0 auto;
		flex-direction: row;
		align-items: center;

		width: var(--base-side-nav-width);
	}

	.TopNavPanel {
		display: flex;
		flex: 1;
		flex-direction: row;
		align-items: center;
		white-space: nowrap;
		width: 100%;

		//background-color: beige;
		.LeftGroup {

			//background-color: forestgreen;
		}

		.Dev {
			padding: var(--base-padding);
			font-weight: bold;
			font-size: 150%;
			//color: var(--theme-color-orange-text);
			color: var(--theme-color-green-text);
			border-radius: var(--base-border-radius);
			background: var(--theme-background-color);
		}

		.RightGroup {

			//background-color: aquamarine;

			.me {
				img.avatar {
					// hack as it's a circle image
					margin-left: calc(4px + var(--base-margin-from-svg));
					vertical-align: middle;
					background: var(--theme-background-color);
					border-radius: 50%;
				}
				.full-name{
					
				}
			}
		}

		.LeftGroup, .RightGroup {
			& > * {
				margin-left: 6px;
				margin-right: 6px;
				//display: inline-block;

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}
