@import "helpers";

.form-control {
	display: block;
	width: 100%;
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;

	//color: #212529;
	color: var(--theme-primary-text-color);
	background-color: #fff;
	background-color: var(--theme-background-color);
	background-color: var(--current-background-color);

	background-clip: padding-box;
	border: 1px solid #ced4da;
	border: 1px solid var(--theme-separator-color);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

input, select, textarea, button {
	@include focusable;

	outline: 0;
	outline-offset: 0;

	// for compound elements with custom focus management
	&.no-outline {
		outline: 0;
		outline-offset: 0;
		box-shadow: none;
	}
}

input[type=text], input[type=password], input[type=number], input:not([type]) {
	&[readonly]{
		background: var(--theme-effect-readonly-bg);
	}
}

:root {
	--current-background-color: red;
}

//TODO determine if still necessary once the previous stuff is cleaned-up
.CrudTopActionBar {
	background-color: var(--theme-background-color);
	--current-background-color: var(--theme-background-color);
}

.top-right-pill {
	&::after {
		content: " ";
		padding: 4px;
		border-radius: 50%;
		background: var(--theme-pill-color);
		//background: red;
		border: 2px solid var(--current-background-color);
		//border: 2px solid blue;
		position: absolute;
		top: 2px;
		right: 2px;
		z-index: var(--base-z-pill);
	}
}

.ActionIconButton {
	position: relative;
}

.modal-700 {
	width: 700px;
}

.icon-inside-20 {
	svg {
		width: 20px !important;
		height: 20px !important;
	}
}

svg {
	&.icon-green {
		color: var(--theme-color-green-text);
	}

	&.icon-blue {
		color: var(--theme-color-blue-text);
	}

	&.icon-red {
		color: var(--theme-color-red-text);
	}
}

.icon-within-green {
	svg {
		color: var(--theme-color-green-text);
	}
}

.icon-within-blue {
	svg {
		color: var(--theme-color-blue-text);
	}
}

.icon-within-red {
	svg {
		color: var(--theme-color-red-text);
	}
}

.icon-within-grey {
	svg {
		color: var(--theme-color-grey-text);
	}
}

.hover-success {
	// active to keep it working when the mouse button is pressed and then the cursor is moved outside of the area
	&:hover, &:active {
		color: var(--theme-color-green-text);
	}
}

.hover-info {
	&:hover, &:active {
		color: var(--theme-color-blue-text);
	}
}

.hover-warning {
	&:hover, &:active {
		color: var(--theme-color-orange-text);
	}
}

.hover-error {
	&:hover, &:active {
		color: var(--theme-color-red-text);
	}
}

.text-grey {
	color: var(--theme-color-grey-text);
}

.cursor-help {
	cursor: help;
}

.general-margin-top {
	margin-top: var(--base-default-margin);
}
.general-margin-bottom {
	margin-bottom: var(--base-default-margin);
}
