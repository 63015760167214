// Source: https://getbootstrap.com/docs/5.0/components/spinners/#border-spinner
.Spinner {
	display: inline-block;
	//width: 2rem;
	//height: 2rem;
	width: 26px;
	height: 26px;
	vertical-align: -0.125em;
	border: 0.25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	-webkit-animation: .75s linear infinite spinner-border;
	animation: .75s linear infinite spinner-border;

	@keyframes spinner-border {
		to {
			transform: rotate(360deg) #{"/* rtl:ignore */"};
		}
	}
}
