@import "/src/assets/scss/helpers";

.LogPanel {
	position: relative;
	min-height: 50px;

	> .LogPanelRow {
		@include default-font-mono();
		white-space: break-spaces;
		min-height: 14px;

		a {
			@include default-font();
			//TODO have contrast text color in a better place than current --theme-constrast-text-color that is not reset depending on context
			//color: var(--theme-secondary-color);
			color: var(--theme-link-color);
		}
	}

	> .LogPanelActions {
		float: right;
		// to compensate the padding of Card, TODO find better way, perhaps just integrate Card inside LogPanel
		margin: -10px -20px;

		> .IconButton + .IconButton {
			margin-left: var(--base-padding);
		}
	}

	.horizontal-spacing{
		width: calc(3 * var(--base-padding));
		display: inline-block;
	}
}
