

.JiraTicketDisclosurePage {
	.Table2.fluid {
		letter-spacing: .2px;

		.column-key {
			max-width: 180px;
			flex-basis: 180px;
		}
		.column-openLink {
			max-width: 50px;
			flex-basis: 50px;
		}

		.column-status {
			max-width: 150px;
			flex-basis: 150px;
		}

		.column-summary {
			flex: 1 1 auto;
			min-width: 0;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.column-accept {
			max-width: 50px;
			flex-basis: 50px;
		}
		.column-requireMoreAttention {
			max-width: 50px;
			flex-basis: 50px;
		}
		.column-markAsVulnerability {
			max-width: 50px;
			flex-basis: 50px;
		}
	}
	
	.status-label {
		// from Jira
		font-size: 11px;
		font-weight: 700;
		line-height: 1;
		margin: 0;
		padding: 3px 5px;
		border-radius: 3px;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;

		vertical-align: text-bottom;
		letter-spacing: 0;
		display: inline-block;

		&.status-color-blue-gray {
			background-color: #42526e;
			border-color: #42526e;
			color: #fff;
		}

		&.status-color-green {
			background-color: #00875a;
			border-color: #00875a;
			color: #fff;
		}

		&.status-color-yellow {
			background-color: #0052cc;
			border-color: #0052cc;
			color: #fff;
		}

		&.status-color-unknown {

		}
	}
}
