:root {
	--base-border-radius: 4px;

	--base-nav-top-height: 64px;
	--base-nav-top-line-height: 44px;

	--base-side-nav-width: 220px;
	--base-side-nav-link-height: 36px;
	--base-margin-from-svg: 5px;
	--base-default-margin: 8px;
	--base-two-margin: calc(2 * var(--base-default-margin));

	--base-padding: 8px;

	--base-z-side-nav: 1;
	--base-z-top-nav: 2;
	--base-z-pill: 20;
	--base-z-portal: 100;
	--base-z-dropdown: 150;

	--base-general-font: Roboto, "Helvetica Neue", sans-serif;
	--base-monospace-font: Consolas, Courier New, Courier, monospace;
	// Perhaps Consolas is not available for unix system
	//--base-monospace-font: Courier New, Courier, monospace;
}

:root {
	$theme: 'light';

	$primaryColor: #673AB7;
	$secondaryColor: #ffb700;
	$primaryTextColor:#212121;
	$backgroundColor: #FEFEFE;
	//$themeBackgroundColor: #FEFEFE;

	//$white: #000000;
	//$black: #FFFFFF;
	$black: #000000;
	$white: #FFFFFF;
	// full transparency to ease opacity
	$baseEffect: transparentize($black, 1);
	$oppositeEffect: transparentize($white, 1);

	--theme-primary-color: #{$primaryColor};
	--theme-secondary-color: #{$secondaryColor};

	--theme-pill-color: #{$primaryColor};

	// for regular text on main content
	--theme-primary-text-color: #{$primaryTextColor};
	--theme-primary-effect-text-color: #{lighten($primaryTextColor, 8%)};
	--theme-less-important-text-color: #{transparentize($primaryTextColor, 0.5)};
	--theme-background-color: #{$backgroundColor};
	--theme-background-alternative-color: #{darken($backgroundColor, 8%)};

	--theme-placeholder-bg: #{darken($backgroundColor, 8%)};

	// different between light and dark theme
	--theme-checkbox-color: #{$primaryColor};
	--theme-checkbox-hover-bg: #{opacify($baseEffect, 0.05)};
	--theme-checkbox-active-bg: #{opacify($baseEffect, 0.15)};

	// for button text, icon
	--theme-constrast-text-color: #FEFEFE;
	--theme-divider-color: #BDBDBD;
	--theme-border-color: #BDBDBD;

	--theme-black: #{$black};
	--theme-white: #{$white};

	--theme-link-color: #{$primaryColor};

	--theme-background-hover-color: #{darken($backgroundColor, 8%)};
	--theme-background-active-color: #{darken($backgroundColor, 16%)};

	//--theme-effect-hover-bg: #{opacify($baseEffect, 0.05)};
	--theme-effect-hover-bg: #{opacify($baseEffect, 0.10)};
	//--theme-effect-active-bg: #{opacify($baseEffect, 0.15)};
	--theme-effect-active-bg: #{opacify($baseEffect, 0.20)};
	--theme-effect-disabled-color: #{opacify($baseEffect, 0.3)};

	--theme-effect-readonly-bg: var(--theme-effect-hover-bg);

	--theme-primary-disabled-color: #{opacify($oppositeEffect, 0.3)};

	//--theme-side-nav-bg: #{lighten($primaryColor, 8%)};
	--theme-side-nav-bg: var(--theme-background-color);

	--theme-separator-color: #{opacify($baseEffect, 0.12)};

	--theme-scrollbar-thumb-color: rgba(0, 0, 0, 0.15);
	--theme-scrollbar-thumb-hover-color: rgba(0, 0, 0, 0.3);

	--theme-color-red-text: #af0900;
	--theme-color-red-bg: #ffdbde;
	--theme-color-red-border: #f5c2c7;

	--theme-color-green-text: #007800;
	--theme-color-green-bg: #c3f3d7;
	--theme-color-green-border: #9fe79f;

	--theme-color-blue-text: #0048a5;
	--theme-color-blue-bg: #cfe2ff;
	--theme-color-blue-border: #b6d4fe;

	--theme-color-orange-text: #d16900;
	--theme-color-orange-bg: #fff3cd;
	--theme-color-orange-border: #f1dfac;

	--theme-color-grey-text: #566365;
	--theme-color-grey-bg: #dee2e8;
	--theme-color-grey-border: #9ca4b0;

	--theme-color-purple-text: #8c00a1;
	--theme-color-purple-bg: #e1a7ee;
	--theme-color-purple-border: #9d43d9;
}

.dark-theme {
	$theme: 'dark';

	$primaryColor: #424242;

	$secondaryColor: #FFC107;
	$primaryTextColor: #FEFEFE;
	$backgroundColor: #353535;

	$black: #FFFFFF;
	$white: #000000;
	$baseEffect: transparentize($black, 1);
	$oppositeEffect: transparentize($white, 1);

	--theme-primary-color: #{$primaryColor};
	--theme-secondary-color: #{$secondaryColor};

	// Better on black to have high contrast
	--theme-pill-color: #{$secondaryColor};

	// for regular text on main content
	--theme-primary-text-color: #{$primaryTextColor};
	--theme-primary-effect-text-color: #{darken($primaryTextColor, 8%)};
	--theme-less-important-text-color: #{transparentize($primaryTextColor, 0.5)};
	--theme-background-color: #{$backgroundColor};
	--theme-background-alternative-color: #{lighten($backgroundColor, 8%)};
	//--theme-background-alternative-color2: lighten(#{$backgroundColor}, 8%);

	--theme-background-hover-color: #{lighten($backgroundColor, 8%)};
	--theme-background-active-color: #{lighten($backgroundColor, 16%)};

	--theme-placeholder-bg: #{darken($backgroundColor, 5%)};

	// different between light and dark theme
	--theme-checkbox-color: #{$secondaryColor};
	--theme-checkbox-hover-bg: #{opacify($baseEffect, 0.1)};
	--theme-checkbox-active-bg: #{opacify($baseEffect, 0.2)};

	// for button text, icon
	--theme-constrast-text-color: #FEFEFE;
	--theme-divider-color: #BDBDBD;
	--theme-border-color: #BDBDBD;

	--theme-black: #{$black};
	--theme-white: #{$white};

	--theme-link-color: #{$secondaryColor};

	//--theme-effect-hover-bg: #{opacify($baseEffect, 0.05)};
	--theme-effect-hover-bg: #{opacify($baseEffect, 0.10)};
	//--theme-effect-active-bg: #{opacify($baseEffect, 0.15)};
	--theme-effect-active-bg: #{opacify($baseEffect, 0.20)};
	--theme-effect-disabled-color: #{opacify($baseEffect, 0.3)};

	--theme-effect-readonly-bg: var(--theme-effect-hover-bg);

	--theme-side-nav-bg: #{darken($primaryColor, 4%)};

	--theme-separator-color: #{opacify($baseEffect, 0.12)};

	//--theme-scrollbar-thumb-color: rgba(0, 0, 0, 0.15);
	--theme-scrollbar-thumb-color: #{opacify($baseEffect, 0.15)};
	//--theme-scrollbar-thumb-hover-color: rgba(0, 0, 0, 0.3);
	--theme-scrollbar-thumb-hover-color: #{opacify($baseEffect, 0.3)};

	--theme-color-red-text: #ffbcb9;
	--theme-color-red-bg: #582e31;
	--theme-color-red-border: #942923;

	--theme-color-green-text: #a5f9a5;
	--theme-color-green-bg: #37694b;
	--theme-color-green-border: #2c9e2c;

	--theme-color-blue-text: #ccd4e5;
	--theme-color-blue-bg: #0073a1;
	--theme-color-blue-border: #6298eb;

	--theme-color-orange-text: #fbbb78;
	--theme-color-orange-bg: #7e4d0c;
	--theme-color-orange-border: #cd7414;

	--theme-color-grey-text: #cdd2d7;
	--theme-color-grey-bg: #566365;
	--theme-color-grey-border: #9ca4b0;

	--theme-color-purple-text: #e1a7ee;
	--theme-color-purple-bg: #8c00a1;
	--theme-color-purple-border: #9d43d9;
}
