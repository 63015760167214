.Markdown {
	/* Headings */
	h1, h2, h3, h4, h5, h6 {
		margin-top: 1em;
		margin-bottom: 0.25em;
		font-weight: bold;
	}

	h1 {
		font-size: 2.5em;
		//border-bottom: 2px solid #eaecef;
		border-bottom: 2px solid var(--current-border-color);
		padding-bottom: 0.2em;
	}

	h2 {
		font-size: 2em;
		//border-bottom: 1px solid #eaecef;
		border-bottom: 1px solid var(--current-border-color);
		padding-bottom: 0.2em;
	}

	h3 {
		font-size: 1.75em;
	}

	h4 {
		font-size: 1.5em;
	}

	h5 {
		font-size: 1.25em;
	}

	h6 {
		font-size: 1em;
		color: #6a737d;
	}

	/* Paragraphs */
	p {
		margin-bottom: 1em;
	}

	/* Blockquotes */
	blockquote {
		margin: 1.5em 10px;
		padding: 0.5em 10px;
		border-left: 5px solid var(--current-background-active-color);
		color: var(--current-text-effect-color);
		background-color: var(--current-background-effect-color);
		
		& > p:last-child {
			margin-bottom: 0;
		}
	}

	/* Lists */
	ul, ol {
		margin-top: 0;
		margin-bottom: 1em;
		padding-left: 40px;
	}

	li {
		margin-bottom: 0.5em;
	}

	ul li {
		list-style: disc;
	}
	ol li {
		list-style: decimal;
	}

	/* Code */
	pre {
		background-color: var(--current-background-effect-color);
		padding: 10px;
		overflow: auto;
		border-radius: 5px;
		margin-bottom: 0.5em;
	}

	code {
		font-family: 'Courier New', Courier, monospace;
		background-color: var(--current-background-effect-color);
		padding: 2px 4px;
		font-size: 0.95em;
		border-radius: 3px;
	}

	pre > code {
		padding: 0;
	}

	/* Horizontal rule */
	hr {
		border: 0;
		height: 1px;
		//background: #eaecef;
		background-color: var(--current-background-effect-color);
		margin: 2em 0;
	}

	/* Links */
	a {
		//text-decoration: none;
		&, &:active, &:visited, &:hover {
			color: var(--theme-link-color);
		}
	}

	a:hover {
		text-decoration: underline;
	}

	/* Images */
	img {
		max-width: 100%;
		height: auto;
	}

	/* Tables */
	table {
		width: 100%;
		border-collapse: collapse;
		margin-bottom: 1em;
	}

	table th, table td {
		padding: 0.5em;
		//border: 1px solid #eaecef;
		border: 1px solid var(--current-border-color);
	}

	table th {
		//background-color: #f6f8fa;
		background-color: var(--current-background-effect-color);
		text-align: left;
	}

	/* Emphasis */
	em {
		font-style: italic;
	}

	strong {
		font-weight: bold;
	}

	/* Other elements */
	del {
		text-decoration: line-through;
	}
}