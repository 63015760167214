.MultiSelectGroup {
	position: relative;

	//display: inline-block;
	display: inline-flex;
	align-items: center;
	//justify-content: center;
	padding: 0 16px;
	min-width: 64px;

	font-size: 14px;
	font-weight: 500;
	line-height: 36px;
	text-decoration: none;
	text-align: center;
	white-space: nowrap;
	//cursor: pointer;
	user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;

	vertical-align: middle;
	border-radius: var(--base-border-radius);

	> .MultiSelectGroupItem {
		cursor: pointer;

		> svg {
			position: relative;
			//z-index: 10;
			vertical-align: middle;

			height: 24px;
			width: 24px;
		}
		
		&.selected {
			color: var(--theme-secondary-color);
		}
	}
	
	> .MultiSelectGroupLabel + .MultiSelectGroupItem,
	> .MultiSelectGroupItem + .MultiSelectGroupItem {
		margin-left: var(--base-default-margin);
	}
}
