@import "/src/assets/scss/helpers";

.QueryDisplay {
	//position: relative;
	//padding: calc(1.5 * var(--base-padding)) calc(2 * var(--base-padding));
	//border: 1px solid transparent;
	//border-radius: 0.25rem;
	//color: #664d03;
	//background-color: #fff3cd;
	//border-color: #ffecb5;

	> .loading {
		padding: calc(2 * var(--base-padding));
	}

	> .error {
		display: inline-block;
	}
}
