@import "/src/assets/scss/helpers";

.DropdownMenu2 {
	.DropdownArea {
		width: 100%;
		position: relative;

		.DropdownMenuPart {
			@include shadow(3);

			position: absolute;

			&.not-open {
				display: none;
			}

			&.open-left {
				right: 0;
			}

			&.open-up {
				bottom: 0;
			}

			max-height: 300px;
			overflow-y: auto;
			@include custom-scrollbar;

			//display: flex;
			//flex-direction: column;
			border-radius: var(--base-border-radius);

			background-color: var(--current-background-color);

			z-index: var(--base-z-dropdown);

			.menu-loading {
				color: var(--theme-less-important-text-color);
				padding: 32px 25px;
			}

			.DropdownMenuItem {

				min-width: 150px;
				padding: 12px;
				user-select: none;
				-ms-user-select: none;
				-webkit-user-select: none;

				&.selected {
					background-color: var(--theme-effect-active-bg);
				}

				&.enabled {
					@include focusable;
					@include active;

					&:not(.selected) {
						@include hover;
					}

					cursor: pointer;
				}

				&.disabled {
					color: var(--theme-effect-disabled-color);
				}
			}
		}
	}
}
