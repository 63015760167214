@import "/src/assets/scss/helpers";

// Copied from JiraStatus

.CommandStatus {
	// from Jira
	//background: var(--aui-lozenge-bg-color);
	border: 0;
	border-radius: 3px;
	//color: var(--aui-lozenge-text-color);
	display: inline-block;
	font-size: 11px;
	font-weight: 700;
	line-height: 1;
	margin: 0;
	//padding: 2px 5px 2px 5px;
	padding: 3px 5px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;

	vertical-align: text-bottom;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 12em;
	word-wrap: normal;
	word-break: normal;

	&.purple {
		background-color: #8a0d6b;
		border-color: #8a0d6b;
		color: #fff;
	}

	&.blue-gray {
		background-color: #42526e;
		border-color: #42526e;
		color: #fff;
	}

	&.green {
		background-color: #00875a;
		border-color: #00875a;
		color: #fff
	}

	&.warm-red {
		background-color: #de350b;
		border-color: #de350b;
		color: #fff
	}
	&.yellow {
		// yeah "yellow"
		background-color: #0052cc;
		border-color: #0052cc;
		color: #fff
	}
	&.braun {
		background-color: #ff991f;
		border-color: #ff991f;
		color: #172b4d
	}
}
