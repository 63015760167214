.Error403Page {
	//display: flex;
	//flex: 1;

	margin: 20px 30px;
	border-radius: var(--base-border-radius);
	//border-top-right-radius: var(--base-border-radius);
	//border-top-left-radius: var(--base-border-radius);

	.section-page-content > * + * {
		margin-left: calc(2 * var(--base-padding));
	}

}
