@import "/src/assets/scss/helpers";

table.Table2 {
	padding: 0;
	width: 100%;
	border-spacing: 0;
	table-layout: fixed;
	border-collapse: collapse;
	box-sizing: border-box;

	// We cannot use fixed layout due to the desire to have fixed columns and expandable ones (like description)
	// table-layout: fixed;

	//svg {
	//	//font-size: 24px;
	//	height: 24px;
	//	width: 24px;
	//}

	&.fluid {
		--row-height: 26px;
		--second-row-height: 18px;
		--padding-right: 12px;
		--padding-left: 12px;
		--padding-top-bottom: 10px;

		display: block;

		letter-spacing: .2px;

		thead {
			display: block;

			tr {
				display: flex;
				//margin-bottom: 1px;
				position: relative;

				//-webkit-box-shadow: inset 0 -1px 0 0 var(--theme-separator-color);
				//box-shadow: inset 0 -1px 0 0 var(--theme-separator-color);
				border-bottom: 1px solid var(--theme-separator-color);

				th {
					height: auto;
					position: relative;
					line-height: var(--row-height);

					padding-bottom: var(--padding-top-bottom);
					padding-top: var(--padding-top-bottom);
					padding-right: var(--padding-right);

					vertical-align: middle;
					text-align: left;
					flex: 0 0 auto;

					.header {
						display: flex;
						align-items: center;
						margin-right: 12px;

						&.sortable {
							cursor: pointer;

							.header-sort-icon {
								position: absolute;
								right: 4px;
								top: var(--padding-top-bottom);

								svg.order-icon {
									font-size: 24px;
									opacity: 0.5;
									margin-left: var(--base-margin-from-svg);
								}
							}
						}
						&.sortable-but-fix {
							.header-sort-icon {
								position: absolute;
								right: 4px;
								top: var(--padding-top-bottom);

								svg.order-icon {
									font-size: 24px;
									opacity: 0.5;
									margin-left: var(--base-margin-from-svg);
								}
							}
						}

						&:hover {
							svg.order-icon {
								opacity: 1;
							}
						}
					}
				}
			}
		}

		tbody {
			display: block;

			//TODO test this
			&.content-loading {
				@include background-strip-moving();

				tr {
					td {
						border-bottom-color: var(--theme-separator-color);
					}
				}
			}

			tr + tr.SecondRow {
				border-top-width: 0;
			}

			tr {
				display: flex;
				position: relative;

				//border-bottom: 1px solid var(--theme-separator-color);
				border-top: 1px solid var(--theme-separator-color);

				&:first-child {
					border-top-width: 0;
				}
				&:last-child {
					border-bottom: 1px solid var(--theme-separator-color);
				}

				td {
					//height: var(--row-height);
					height: auto;
					line-height: var(--row-height);
					vertical-align: middle;
					white-space: nowrap;
					outline: none;

					padding-bottom: var(--padding-top-bottom);
					padding-top: var(--padding-top-bottom);
					padding-right: var(--padding-right);

					align-items: center;
					flex: 0 0 auto;

					& > span {
						// no effect, at least when inside StringColumnDef, the span is as wide as the content
						overflow: hidden;
						text-overflow: ellipsis;
					}

					&.inline-code {
						@include default-font-mono;
					}

					&.td-icon {
						padding: 0 var(--padding-right) 0 0;

						.IconButton, .ExternalLinkIconButton, .InternalLinkIconButton {
							// at least valid for size-m
							//margin-top: 7px;
							//margin-top: 4px;
							margin-top: var(--padding-top-bottom);
						}

						> .NullCellValue {
							line-height: var(--row-height);
							margin-top: var(--padding-top-bottom);
							width: 36px;
							height: 36px;
							display: block;
						}
					}

					.NullCellValue {
						color: var(--theme-less-important-text-color);
					}

					&.ImageColumnDef {
						padding-top: 0;
						padding-bottom: 0;
						// to avoid forcing wrong height
						line-height: 0;
						> img {
							// 5px + 36px + (5px bottom) = 46px
							margin-top: 5px;
						}
						> .NullCellValue {
							line-height: var(--row-height);
							margin-top: var(--padding-top-bottom);
							width: 36px;
							height: 36px;
							display: block;
						}
					}
					&.BooleanColumnDef {
						padding-top: 0;
						padding-bottom: 0;
						display: flex;
					}
				}

				&.TableLoadingRow {
					td {
						flex: 1 1;
						height: 100%;
						//line-height: 32px;
						padding-left: var(--padding-top-bottom);

						.loading {
							display: flex;

							.loading-message {
								margin-left: var(--padding-top-bottom);
							}
						}
					}
				}

				&.Row {
					td {
						flex: 1 1;
						height: 100%;
					}
				}

				&.SecondRow {
					td {
						line-height: var(--second-row-height);
						flex: 1 1;
						height: 100%;

						padding-left: 12px;
						padding-right: var(--padding-right);
						padding-top: 0;

						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}

		// common columns
		.fix-column-select {
			padding-left: 13px;
			padding-right: var(--padding-right);
			position: relative;

			> label {
				margin-top: -3px;
			}
		}
		
		.StringColumnDef {
			@include column-string-limit();

			padding-right: 0;
			margin-right: var(--padding-right);
		}
		
		.BooleanColumnDef {
			@include column-fix-boolean();
		}

		.IconColumnDef {
			@include column-fix-icon();
		}

		.DateColumnDef {
			@include column-fix-date();
		}

		.IsoDateTimeColumnDef {
			@include column-fix-iso-datetime();
		}

		.ExternalLinkColumnDef {
			@include column-fix(45px);
		}

		.InternalLinkColumnDef {
			@include column-fix(45px);
		}

		.column-id {
			@include column-fix(150px);
		}

		// Different column sizes, see ColSizeType
		.column-size-expand {
			@include column-expand();
		}

		// Generate 25, 26, 27, 27, ...
		//@for $colSize from 25 through 350 {
		//	.column-size-#{$colSize} {
		//		@include column-fix(#{$colSize}px);
		//	}
		//}

		// See ColSizeType.ts for the type
		$colSize: 25;
		@while $colSize <= 350 {
			.column-size-#{$colSize} {
				@include column-fix(#{$colSize}px);
			}
			$colSize: $colSize + 5;
		}
	}
}
