@import "~typeface-roboto/index.css";
@import "helpers";

html, body {
	height: 100%;
	width: 100%;
}

body {
	@include default-font;
	margin: 0;

	display: flex;
	flex: 1;
	flex-direction: column;
}

p {
	margin-top: 0;
	margin-block-start: 0;
	margin-bottom: 0;
	margin-block-end: 0;
}

p + p {
	margin-top: 12px;
	margin-block-start: 12px;
}

a {
	&, &:visited {
		text-decoration: none;
	}
	&, &:active, &:visited, &:hover {
		color: var(--theme-default-text-color);
		//color: var(--theme-link-color);
	}
}

pre {  
	white-space: break-spaces;
}

//TODO move to a base button component 
// from https://gist.github.com/MoOx/9137295
button, .button {
	cursor: pointer;

	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;

	outline: none;
	border-radius: 0;

	background: transparent;

	/* inherit font & color from ancestor */
	color: inherit;
	font: inherit;

	/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
	line-height: normal;

	/* Corrects font smoothing for webkit */
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;

	/* Corrects inability to style clickable `input` types in iOS */
	-webkit-appearance: none;

	text-align: left;
}
