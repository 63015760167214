@import "/src/assets/scss/helpers";

.CrudPage2 {
	border-radius: var(--base-border-radius);

	.CrudTopActionBar {
		padding: 12px;
		display: flex;
		flex-direction: row;

		.Title {
			flex: 0 1 auto;
			margin-left: 16px;
			margin-right: 36px;

			font-size: 20px;
			line-height: 36px;
		}
	}

	.CrudBottomActionBar {
		display: flex;
		flex: 1 1;
		justify-content: space-between;

		padding: 12px;

		.part {
			.Selection {
				padding-left: 8px;
				line-height: 36px;
				display: inline-flex;

				font-size: 16px;
				
				.SelectionCount{
					margin-right: var(--base-default-margin);
				}
			}
		}
	}
}
