@import "/src/assets/scss/helpers";

.SideNav {
	//background-color: blue;
	flex: 0 0 auto;
	padding-top: 10px;
	width: var(--base-side-nav-width);
	z-index: var(--base-z-side-nav);

	@include redefine-background(--theme-side-nav-bg, --theme-side-nav-hover-bg, --theme-side-nav-active-bg);

	& > * {
		margin-top: 6px;
		margin-bottom: 6px;
		//display: inline-block;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.Dev {
		padding: calc(2 * var(--base-padding));
		text-align: center;
		font-weight: bold;
		font-size: 120%;
		color: var(--theme-color-green-text);
		border-radius: var(--base-border-radius);
	}
}
