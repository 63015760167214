.CrudForm {
	& {
		//TODO use a mixin for vertical alignment
		& > * {
			margin-top: var(--base-default-margin);

			&:first-child {
				margin-top: 0;
			}
		}
	}

	.beforeForm {
		// cancel the single margin-top from the next element
		margin-bottom: calc(3 * var(--base-default-margin));
	}

	.afterForm {
		margin-top: calc(3 * var(--base-default-margin));
	}
}
