@import "/src/assets/scss/helpers";

.FormRow {
	.row-label {
		text-align: right;
		padding-top: 11px;

		label {
		}

		.tooltip-help {

		}
	}

	.row-value {
		.inline-help {

		}

		.error-message {

		}
	}
}
