@import "../../../../assets/scss/helpers";

.TopNavMenu {
	display: inline-block;
	//position: relative;

	padding: 0 8px;
	border-radius: var(--base-border-radius);

	line-height: 36px;
	font-weight: 500;

	//color: var(--theme-constrast-text-color);
	color: var(--current-color);

	&.disabled {
		cursor: initial;
		color: var(--theme-primary-disabled-color, var(--theme-effect-disabled-color));
	}

	svg {
		font-size: 24px;
		vertical-align: middle;
	}

	.label {
		display: inline-block;
		vertical-align: middle;
	}

	// with icon
	svg + .label {
		margin-left: var(--base-margin-from-svg);
	}

	// with afterIcon
	.label + svg {
		margin-left: var(--base-margin-from-svg);
	}

	//TODO not working due to the position:relative that cannot be placed on the TopNavMenu otherwise
	// the overflow-x auto is impacting the regular scroll in mobile mode, leading to the menu appearing inside the TopNav
	// creating a vertical scroll need
	
	//&.aligned-to-left {
	//	.TopNavMenuList {
	//		left: 0;
	//		margin-left: calc(-1 * var(--base-default-margin));
	//		margin-right: var(--base-default-margin);
	//	}
	//}
	//
	//&.aligned-to-right {
	//	.TopNavMenuList {
	//		right: 0;
	//		margin-left: var(--base-default-margin);
	//		margin-right: calc(-1 * var(--base-default-margin));
	//	}
	//}

	&.close {
		//.TopNavMenuHeader {
		//	background-color: var(--theme-effect-active-bg);
		//}
	}
	&.open {
		.TopNavMenuHeader {
			background-color: var(--theme-effect-active-bg);
		}
	}

	.TopNavMenuHeader {
		padding: 4px 8px;
		
		cursor: pointer;
		user-select: none;
		-ms-user-select: none;
		-webkit-user-select: none;

		&:not(.disabled) {
			//TODO could be reused

			&.hover, &:hover {
				background-color: var(--theme-effect-hover-bg);
			}

			&.active, &:active {
				background-color: var(--theme-effect-active-bg);
			}
		}
	}

	.TopNavMenuList {
		@include shadow(5);
		// cut the upper shadow
		clip-path: inset(0px -10px -10px -10px);
		
		position: absolute;
		background-color: var(--current-background-color);
		margin-top: var(--base-default-margin);
		padding-top: var(--base-padding);
		padding-bottom: var(--base-padding);

		@include media-breakpoint-down(md){
			// on smaller screen, display the menu on the whole line 
			left:0;
			right:0;
			margin: 0 var(--base-default-margin);
		}
		
		.TopNavMenuItem {
			display: inline-block;
			padding: 4px 16px;
			line-height: 24px;
			min-width: 140px;

			cursor: pointer;
			user-select: none;
			-ms-user-select: none;
			-webkit-user-select: none;

			& + .TopNavMenuItem {
				margin-top: 4px;
			}

			&.hover, &:hover {
				background-color: var(--theme-effect-hover-bg);
			}

			&.active, &:active {
				background-color: var(--theme-effect-active-bg);
			}
		}
	}
}
