@import "/src/assets/scss/helpers";

.Tabs {
	@include shadow(3);
	margin-bottom: calc(2 * var(--base-padding));

	> .tab-header-list {
		//@include shadow(3);
		//display: inline-block;

		padding-left: var(--base-padding);
		padding-right: var(--base-padding);
		// to make it "transparent"
		border-bottom: 1px solid var(--theme-border-color);;

		> .TabHeader {
			display: inline-block;
			padding: var(--base-padding);
			margin-top: var(--base-padding);

			// To compensate the border-bottom for the selected tab, otherwise there is an additional space
			margin-bottom: calc(var(--base-padding) - 1);

			cursor: pointer;

			border: 1px solid var(--theme-border-color);

			&.selected {
				padding-bottom: calc(2 * var(--base-padding));
				margin-bottom: -1px;

				border-bottom-color: var(--theme-background-color);
			}

			&.enabled {
				@include focusable;
				@include hover;
				@include active;

				cursor: pointer;
			}

			&.disabled {
				color: var(--theme-effect-disabled-color);
			}
		}

		> .TabHeader + .TabHeader {
			//margin-left: calc(2 * var(--base-padding));
			margin-left: var(--base-padding);
		}
	}

	> .tab-content-list {
		padding: var(--base-padding);

		> .TabContent {
			display: none;

			&.selected {
				display: block;
			}
		}

		//padding-top: var(--base-padding);
		//padding-bottom: var(--base-padding);

		//border: 1px solid var(--theme-border-color);
	}
}
