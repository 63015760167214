@import "/src/assets/scss/helpers";

.SecurityTaskDetailsPage {
	margin: 20px 30px;

	.NavigationRow {
		* + * {
			margin-left: 12px;
		}
	}

	.comment-row {
		.comment-header {
			color: var(--theme-less-important-text-color);
			
			.comment-author {
				//@include default-font-mono;
				font-weight: bold;
			}
			.comment-updated-date {
			}

			margin-bottom: var(--base-default-margin);
		}
		.comment-body {
			
		}
	}
	.comment-row + .comment-row {
		margin-top: var(--base-two-margin);
	}
}
