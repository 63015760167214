@import "/src/assets/scss/helpers";

$dividerMargin: 8px;

.Divider {
	margin: $dividerMargin;
	border-top: 1px solid var(--theme-divider-color);

	&.no-horizontal-margin {
		margin-left: 0;
		margin-right: 0;
	}
}
