@import "/src/assets/scss/helpers";

.MetaInfoIcon {
	@at-root {
		svg#{&} {
			font-size: 24px;
			vertical-align: middle;
		}
	}
}
