@import "/src/assets/scss/helpers";

.TopNavIcon {
	display: inline-block;

	.IconButton {
		line-height: 36px;
		margin: 4px 12px;
		
		
		
		////TODO make it working using a contextual variable like background but for outline within header
		//@include focusable;
		//@include active;
		//@include hover;
		//
		//display: inline-block;
		//
		//padding: 4px 12px;
		//border-radius: 50%;
		//
		//line-height: 36px;
		//cursor: pointer;
		//user-select: none;
		//-ms-user-select: none;
		//-webkit-user-select: none;
		//
		//svg {
		//	font-size: 24px;
		//	vertical-align: middle;
		//}
	}
}
