@import "../../../../assets/scss/helpers";

.SendEmailsPage {
	.TicketCard {
		margin-top: 16px;
	}

	.RecipientCard {
		margin-top: var(--base-default-margin);

		.CurrentRecipientList {
			.User + .User {
				margin-top: var(--base-default-margin);
			}

			.User {
				line-height: 24px;

				span {
					vertical-align: top;
				}

				span + span {
					margin-left: var(--base-default-margin);
				}

				> .type {
					height: 24px;
					display: inline-block;

					svg {
						height: 24px;
						width: 24px;
					}
				}

				> .recipient-type {
					.RecipientTypeChoice {
						margin-left: var(--base-default-margin);
						margin-right: var(--base-two-margin);

						> span {
							cursor: pointer;

							&.active {
								font-weight: bold;
							}

							&:not(.active) {
								color: var(--theme-color-grey-text);
								--current-text-color: var(--theme-color-grey-text);
							}

							&:hover {
								border-bottom: solid 1px var(--current-text-color);
							}
						}
					}
				}

				> .display-name {
					width: 150px;
					display: inline-block;
				}

				> .login {
					width: 150px;
					display: inline-block;

					@include default-font-mono();
				}

				> .email {
					width: 250px;
					display: inline-block;

					@include default-font-mono();
				}
			}

			.ManualEmail {

			}
		}
	}

	.EmailEdition {
		margin-top: var(--base-default-margin);

		.EmailTemplate {

		}

		.EmailPreview {

		}

		.EmailPart {
			> .subject {
				margin-bottom: var(--base-two-margin);
				font-size: 120%;
			}

			> .body {
				white-space: pre-line;
			}
		}
	}

	.SendPart {
		margin-top: var(--base-default-margin);
	}
}
