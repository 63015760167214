@import "/src/assets/scss/helpers";

.Portal {
	> .modal-backdrop {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		//background-color: rgba(51, 51, 51, 0.5);
		background-color: rgba(0, 0, 0, 0.5);
		// not supported by Firefox
		//backdrop-filter: blur(1px);

		// close effect
		opacity: 0;
		transition: opacity 150ms ease-in 10ms;
		//transition-duration: 200ms;

		display: flex;
		align-items: center;
		justify-content: center;
		z-index: var(--base-z-portal);

		> .modal-content {
			// close effect
			opacity: 0;
			transform: translateY(100px);
			transition: opacity 150ms ease-in 10ms, transform 150ms ease-in 10ms;

			// See Modal.ts for the type
			$widthSize: 500;
			@while $widthSize <= 1000 {
				&.modal-width-#{$widthSize} {
					width: #{$widthSize}px;
				}
				$widthSize: $widthSize + 50;
			}
		}

		&.active {
			// open effect
			opacity: 1;
			transition-duration: 300ms;

			> .modal-content {
				// open effect
				opacity: 1;
				transform: translateY(0);
				transition: opacity 250ms ease-in 50ms, transform 250ms ease-in 50ms;
			}
		}

		> .modal-content {
			position: relative;
			padding: 20px;
			//box-sizing: border-box;
			min-height: 50px;
			min-width: 50px;
			max-height: 90%;
			max-width: 80%;
			border-radius: 2px;

			@include redefine-color(--theme-primary-text-color);
			@include redefine-background(--theme-background-color);

			@include custom-scrollbar;
		}
	}
}
