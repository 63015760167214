
.AdminToolingPage {
	.AdminSection {
		> h3 {
			margin-top: calc(2 * var(--base-padding));
			margin-bottom: var(--base-padding);
		}

		> .content {
			.AdminLine {
				> * + * {
					margin-left: var(--base-padding);
				}
			}

			.AdminLine + .AdminLine {
				margin-top: var(--base-padding);
			}
		}
	}

	//TODO extract and refactor
	.cursor-pointer {
		cursor: pointer;
	}
}
