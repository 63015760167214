@import "/src/assets/scss/helpers";

a.ExternalLink {
	&, &:active, &:visited, &:hover{
		color: var(--theme-link-color);
		text-decoration: none;
	}

	&:hover {
		text-decoration: underline;
	}
	
	& > svg {
		vertical-align: top;
		margin-left: 3px;
		font-size: 14px;
	}
}
