@import "src/assets/scss/helpers";

.ChatThreadPage {
	.TwoColumns {
		> .Left {
			width: 30%;
			padding-right: 24px;

			@include media-breakpoint-down(md){
				display: none;
			}
		}

		> .Right {
			width: 70%;

			@include media-breakpoint-down(md){
				width: 100%;
			}
		}
		
		.InstructionPanel {
			flex-direction: column;

			.InstructionForm{
				flex-direction: column;
			}
		}
		
		//.ResponseList {}
		.ResponseItem, .InstructionForm {
			display: flex;
			//flex-direction: row;
			padding: 12px 18px;

			@include media-breakpoint-down(md){
				padding: 4px;
				display: block;
				// to make the delete button on the top right corner
				position: relative;
			}

			border-bottom: solid 1px var(--theme-border-color);

			&.in-progress {
				$effect-size: 5px;

				border-left: solid $effect-size var(--theme-secondary-color);
				padding-left: calc(18px - $effect-size);

				.RoleDisplay {
					cursor: initial;
				}

				.fake-DeleteButtonPart {
					width: 40px;
				}
			}
			
			.RoleDisplay {
				display: inline-block;
				//padding: var(--base-padding);
				//padding: 12px;
				padding: 12px 12px 12px 6px;
				min-width: 100px;

				@include media-breakpoint-down(md){
					min-width: 0px;
					margin-right: 4px;
				}

				text-transform: uppercase;
				font-weight: bold;
				cursor: pointer;
				font-size: 12px;
				letter-spacing: .08em;
				line-height: 24px;
			}

			.MessageDisplay {
				//padding: var(--base-padding);
				display: inline-block;
				width: 100%;

				textarea, .fake-textarea {
					background-clip: padding-box;
					border-radius: 3px;
					box-sizing: border-box;
					color: var(--theme-primary-text-color);
					font-family: var(--sans-serif);
					font-weight: 400;
					margin: 0;
					vertical-align: top;

					width: 100%;
					background-color: transparent;
					border: 0 #fff;
					font-size: 16px;
					//line-height: 24px;
					line-height: 18px;
					padding: 12px;

					@include media-breakpoint-down(md){
						padding: 2px;
					}
					
					resize: none;
					//transition: all .4s ease-in-out;

					//white-space: break-spaces;
					overflow: hidden;

					&::placeholder {
						color: var(--theme-less-important-text-color);
						opacity: 1; /* Firefox */
					}
				}
			}

			.button-panel {
				display: flex;

				//width: 40px;
				text-align: right;
				padding-top: 12px;
				@include media-breakpoint-down(md){
					position: absolute;
					top: 0;
					right: 0;
				}

				.message-button {
					width: 32px;
					text-align: right;
					margin-left: 4px;
					
					@include media-breakpoint-down(md){
						height: 32px;
					}
					//padding-top: 12px;
					//@include media-breakpoint-down(md){
					//	position: absolute;
					//	top: 0;
					//	right: 0;
					//}
				}
			}
		}

		.ResponseItem.editMode {
			background-color: var(--theme-background-alternative-color);

			.MessageDisplay {
				background-color: var(--theme-background-color);

				textarea {
					//transition: all .2s ease-in-out;
				}
			}
		}
	}
}
