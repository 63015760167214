.CustomCheckbox {
	position: relative;
	vertical-align: middle;
	height: 20px;
	width: 20px;
	display: inline-block;

	user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;

	align-items: baseline;
	//display: inline-flex;

	svg {
		height: 24px;
		width: 24px;
		margin: -2px;
		position: relative;
		//z-index: 10;
	}

	&.value-true {
		svg {
			color: var(--theme-checkbox-color);
			//color: green;
		}
	}

	&.value-mixed {
		svg {
			color: var(--theme-checkbox-color);
			//color: red;
		}
	}

	&.value-false {
		svg {
			color: var(--theme-primary-text-color);
			//color: var(--theme-secondary-text-color);
			//color: blue;
		}
	}

	input {
		display: none;
	}

	.effects {
		pointer-events: none;
		position: absolute;
		//left: calc(50% - 20px);
		//top: calc(50% - 20px);
		//height: 40px;
		//width: 40px;
		left: calc(50% - 18px);
		top: calc(50% - 18px);
		height: 36px;
		width: 36px;
		//background-color: grey;
		//z-index: 5;
		border-radius: 50%;
	}

	&.enabled {
		cursor: pointer;

		&:hover {
			.effects {
				//TODO could use a --current version
				background-color: var(--theme-checkbox-hover-bg, var(--theme-effect-hover-bg));
			}
		}

		&:active {
			.effects {
				//TODO could use a --current version
				background-color: var(--theme-checkbox-active-bg, var(--theme-effect-active-bg));
			}
		}
	}

	&.disabled {
		cursor: default;
		//color: var(--theme-checkbox-disabled-color, var(--theme-effect-disabled-color));
		svg {
			color: var(--theme-checkbox-disabled-color, var(--theme-effect-disabled-color));
		}

		//background-color: var(--theme-checkbox-disabled-color, var(--theme-effect-disabled-color));
	}
}
