@import "/src/assets/scss/helpers";

.NonCertComment_TaskSecurityPage {
	.non-cert-comment-second-row {
		color: var(--theme-less-important-text-color);

		.author {
			
		}
		.date {
		}
		
		.content{
			padding-left: var(--padding-left);
		}
	}
}
