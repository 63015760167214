@import "../../../assets/scss/helpers";

.Section {
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;

	> .BelowTopNav {
		display: flex;
		flex: 1;
		flex-direction: row;

		color: var(--theme-primary-text-color);
		--current-text-color: var(--theme-primary-text-color);
		background-color: var(--theme-background-color);
		--current-background-color: var(--theme-background-color);

		> .section-page-content {
			flex: 1;

			width: 100% !important;
			overflow: hidden;
		}

		@include media-breakpoint-down(md){
			&.sidenav-open .SideNav {
				display: block;
			}
			&.sidenav-close .SideNav {
				display: none;
			}
		}
	}

	> .Footer {
		position: fixed;
		bottom: 0;
		right: 0;
		padding: var(--base-padding);

		> .version {
			color: grey;
			font-size: 12px;

			> .version-type {
				padding-left: var(--base-padding);

				&.version-type-dev {
					color: var(--theme-color-orange-text);
					font-weight: bold;
				}
			}
		}
	}
}
