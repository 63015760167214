@import "/src/assets/scss/helpers";

.Card {
	@include shadow(3);

	padding: 20px 30px;

	.CardHeader {
		font-size: 20px;
		font-weight: 500;
		line-height: 24px;
	}

	.CardTitleAndActions {
		display: flex;
		flex-direction: row;
		margin-bottom: 12px;

		> .title {
			flex: 0 1 auto;
			line-height: 36px;
		}

		&.size-big {
			> .title {
				margin-right: 24px;
				font-size: 24px;
				font-weight: 500;
			}
		}

		&.size-medium {
			> .title {
				margin-right: 18px;
				font-size: 18px;
				font-weight: 500;
			}
		}

		&.size-small {
			> .title {
				margin-right: 12px;
				font-size: 16px;
				font-weight: 500;
			}
		}

		> .cardActionBar {

		}

		& + .TwoColumns, & + .DetailsRow {
			margin-top: 18px;
		}
	}
}
