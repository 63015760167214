.LinkButton {
	position: relative;

	//display: inline-block;
	display: inline-flex;
	align-items: center;
	//justify-content: center;
	padding: 0 16px;
	min-width: 64px;

	font-size: 14px;
	font-weight: 500;
	line-height: 36px;
	text-decoration: none;
	text-align: center;
	white-space: nowrap;
	//cursor: pointer;
	user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;

	//box-sizing: border-box;

	vertical-align: middle;
	border-radius: var(--base-border-radius);

	> svg {
		position: relative;
		//z-index: 10;
		vertical-align: middle;
	}

	> svg + .button-label {
		margin-left: var(--base-margin-from-svg);
	}

	> .button-label + svg {
		margin-left: var(--base-margin-from-svg);
	}

	> .button-label {
		// to be at least equal to the .effects
		//z-index: 5;
	}

	> .button-effects {
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: var(--base-border-radius);
		//background-color: grey;
		//z-index: 5;
	}

	@mixin size($iconSize, $effectSize) {
		> svg {
			height: $iconSize;
			width: $iconSize;
		}
	}

	&.size-s {
		//@include size(24px, 36px);
		padding: 0 8px;
		line-height: 26px;
	}

	&.size-m {
		@include size(24px, 36px);
	}

	&.primary {
		color: var(--theme-constrast-text-color);
		background-color: var(--theme-primary-color);
	}

	&.bordered {
		border: 1px solid var(--theme-separator-color);
	}

	&.enabled {
		cursor: pointer;

		&:hover {
			> .button-effects {
				//TODO could use a --current version
				background-color: var(--theme-text-button-hover-bg, var(--theme-effect-hover-bg));
			}
		}

		&:active {
			> .button-effects {
				//TODO could use a --current version
				background-color: var(--theme-text-button-active-bg, var(--theme-effect-active-bg));
			}
		}
	}

	&.disabled {
		cursor: default;
		color: var(--theme-text-button-disabled-color, var(--theme-effect-disabled-color));
	}
}
