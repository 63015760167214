@import "/src/assets/scss/helpers";

.TaskBaseStatusBadge {
	// from Jira
	font-size: 11px;
	font-weight: 700;
	line-height: 1;
	margin: 0;
	padding: 3px 5px;
	border-radius: 3px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;

	vertical-align: text-bottom;
	letter-spacing: 0;
	display: inline-block;

	&.status-todo {
		background-color: #42526e;
		border-color: #42526e;
		color: #fff;
	}

	&.status-progress {
		background-color: #0052cc;
		border-color: #0052cc;
		color: #fff;
	}

	&.status-completed {
		background-color: #00875a;
		border-color: #00875a;
		color: #fff;
	}

	&.status-unknown {
		background-color: #7000cc;
		border-color: #7000cc;
		color: #fff;
	}
}
