@import "/src/assets/scss/helpers";

.LoginPage {
	//display: flex;
	//flex: 1;

	margin: 20px 30px;
	@include media-breakpoint-down(md) {
		margin: 0;
	}

	border-radius: var(--base-border-radius);
	//border-top-right-radius: var(--base-border-radius);
	//border-top-left-radius: var(--base-border-radius);

	.section-page-content {
		display: flex;
	}

	.section-page-content > .centered-content {
		@extend .elevation-z3;

		display: flex;
		flex-direction: column;
		align-items: center;

		width: 30%;
		min-width: 400px;
		@include media-breakpoint-down(md) {
			min-width: 90%;
		}

		height: fit-content;

		margin: auto auto;
		padding: calc(2 * var(--base-padding));

		& > h1 {
			font-size: 32px;
			@include media-breakpoint-down(md) {
				font-size: 22px;
			}
			margin-bottom: 12px;
		}

		.already-connected {
			margin-bottom: calc(2 * var(--base-padding));
		}

		.env-panel {
			margin-bottom: calc(2 * var(--base-padding));
			width: 100%;

			.env-entry {
				.env-value {
					@include default-font-mono;
				}
			}
		}

		.dev-buttons {
			margin-bottom: calc(2 * var(--base-padding));

			& > * + * {
				margin-left: calc(2 * var(--base-padding));
			}
		}

		& > .WarningPanel {
			margin-bottom: calc(2 * var(--base-padding));
		}

		a.sign-in-link {
			align-items: center;
			color: #000;
			background-color: #fff;
			border: 1px solid #ddd;
			border-radius: 4px;
			display: inline-flex;
			font-family: Lato, sans-serif;
			font-size: 18px;
			font-weight: 600;
			height: 48px;
			justify-content: center;
			text-decoration: none;
			width: 256px;

			svg {
				height: 20px;
				width: 20px;
				margin-right: 12px
			}
		}

		//.version {
		//	font-size: 12px;
		//	color: grey;
		//	width: 100%;
		//	text-align: right;
		//	margin-top: calc(2 * var(--base-padding));
		//}
	}

}
