@import "/src/assets/scss/helpers";

.ScrollablePanel {
	position: relative;

	.CopyContent {
		position: absolute;
		top: 1px;
		right: 1px;
		margin-right: 15px;
	}

	.content-to-copy{
		@include custom-scrollbar;

		max-height: 250px;
		overflow-y: auto;
		// with line-height the scroll is not auto but scroll only
		line-height: normal;
	}
}
