@import "/src/assets/scss/helpers";

.SecurityTicketDetailsPage {
	margin: 20px 30px;

	.NavigationRow {
		* + * {
			margin-left: 12px;
		}
	}

	.TicketPanel {
		//.TicketDetails {
		//	margin-top: 24px;
		//}

		.TicketDescription {
			margin-top: 24px;
			//TODO not sure this is a good name
			@include default-font-text;
		}
	}

	.CommentsRow {

	}

	.ActionRow {

	}

	.TasksRow {
		.TaskRow + .TaskRow {
			margin-top: 8px;
		}

		.TaskRow {
			display: flex;
			line-height: 24px;
			// to force the buttons to also have 24px and not 24.3px
			height: 24px;

			.type-name {
				flex-basis: 150px;
			}

			.status {
				flex-basis: 100px;
			}

			.TaskSpecificActions {
				flex: 1 1;
				text-align: right;
			}

			.task-details-link {
				flex: 0 1;
				margin-left: 12px;
				text-align: right;
			}

			.no-task {
				flex: 1 1;
			}
		}

	}

	.OtherTicketForSamePlugin {
		.TicketRow {
			display: flex;
			line-height: 24px;
			height: 24px;

			> .ticket {
				flex-basis: 100px;
			}

			> .summary {
				//flex-basis: 150px;
				flex: 1 1;
				overflow-x: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				padding-left: var(--base-padding);
				padding-right: var(--base-padding);
			}

			> .sprint {
				flex-basis: 100px;
				overflow-x: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				padding-right: var(--base-padding);

				.no-sprint {
					color: var(--theme-color-grey-text);
					font-style: italic;
				}
			}

			> .status {
				flex-basis: 100px;
			}

			> .ticket-details-link {
				flex: 0 1;
				margin-left: 12px;
				text-align: right;
			}
		}

		.closed-tickets-presence {
			margin-top: var(--base-default-margin);
			cursor: pointer;
		}

		.TicketRowEmpty {

		}
	}
}
