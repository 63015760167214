.TopNavLink {
	display: inline-block;
	position: relative;

	padding: 0 8px;
	border-radius: var(--base-border-radius);

	line-height: 36px;
	font-weight: 500;
	cursor: pointer;
	user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;

	//color: var(--theme-constrast-text-color);
	color: var(--current-color);

	&:not(.disabled) {
		&.hover, &:hover {
			//background-color: var(--theme-primary-hover-bg, var(--theme-effect-hover-bg));
			background-color: var(--current-background-hover-color);
		}

		&.active, &:active {
			//background-color: var(--theme-primary-active-bg, var(--theme-effect-active-bg));
			background-color: var(--current-background-active-color);
		}
	}

	&.disabled {
		cursor: initial;
		color: var(--theme-primary-disabled-color, var(--theme-effect-disabled-color));
	}

	svg {
		font-size: 24px;
		vertical-align: middle;
	}

	.label {
		display: inline-block;
		vertical-align: middle;
	}

	// with icon
	svg + .label {
		margin-left: var(--base-margin-from-svg);
	}

	// with afterIcon
	.label + svg {
		margin-left: var(--base-margin-from-svg);
	}
}
