@import "../../../../assets/scss/helpers";

.GitHubAdvisoryToolingPage {
	.dynamic-response-panel {
		@include shadow(3);
		padding: calc(var(--base-padding) * 2);

		.no-entries {

		}

		.response-title {
			font-size: 120%;
			font-weight: bold;
			margin-bottom: var(--base-default-margin);
		}

		.ListingAdvisoryEntry + .ListingAdvisoryEntry {
			margin-top: var(--base-default-margin);
		}

		.ListingAdvisoryEntry {
			svg {
				font-size: 20px;
				vertical-align: middle;
			}

			.ListingAdvisoryName {
				> svg {
					margin-right: var(--base-margin-from-svg);
				}

				.LinkButton, .TextButton {
					margin-left: var(--base-default-margin);
				}

				//.ListAdvisoryId,
				.ListAdvisorySummary,
				.ListAdvisoryState {
					margin-left: var(--base-default-margin);
				}

				.ListAdvisoryState {
					//TODO to become a status label like for Jira
					text-transform: uppercase;
					font-weight: bold;
				}
			}

			ol {
				li {
					margin-left: var(--base-default-margin);
					line-height: 20px;

					&.ListingAdvisoryCredit {
						display: block;
						
						& > * {
							display: inline-block;
						}

						> .ListingAdvisoryCreditType {
							margin-left: var(--base-margin-from-svg);
							min-width: 175px;
						}

						> .ListingAdvisoryCreditLogin {
							margin-left: var(--base-default-margin);
							min-width: 150px;
						}
					}

					.LinkButton, .TextButton {
						margin-left: var(--base-default-margin);
					}
				}

				& > li {
					margin-top: var(--base-default-margin);
				}

				li + li {
					margin-top: var(--base-default-margin);
				}
			}
		}

		.ListingArtifactEntry + .ListingArtifactEntry {
			margin-top: var(--base-two-margin);
		}
	}
}
