@import "/src/assets/scss/theme";
@import "/src/assets/scss/helpers";

//TODO should not be used, native feature available
.ExpandSpace {
	flex-grow: 1;
}

#app-root {
	flex: 1;
	display: flex;

	width: 100%;
}

#non-modal-part {
	flex: 1 1;
	display: flex;
	width: 100%;
}

#modal-root {

}

.ThemeProvider, .AppContainer {
	display: flex;
	flex: 1;
	width: 100%;
}

// Base class for the whole application
.AppContainer {
	//background-color: pink;
	font-size: 14px;
	font-family: Roboto, "Helvetica Neue", sans-serif;

	//color: var(--theme-primary-text-color);

	@include redefine-color(--theme-primary-text-color, --theme-primary-effect-text-color, --theme-less-important-text-color);
	//@include redefine-background(--theme-background-color, --theme-effect-hover-bg, --theme-effect-active-bg);
	@include redefine-background(--theme-background-color, --theme-background-hover-color, --theme-background-active-color);
	
	//TODO use the working version
	//@include redefine-background(--theme-color, --theme-background-color);
	//@include redefine-background(--theme-primary-text-color, --theme-background-color);
	//@include redefine-background(--theme-primary-text-color, --theme-background-color);
	//@include redefine-background($themeColor, $themeBackgroundColor);
	//background-color: var(--theme-background-color);
	//--current-background-color: var(--theme-background-color);
}
