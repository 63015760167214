@import "/src/assets/scss/helpers";
@import "../../Divider";

$labelMargin: 8px;
$labelHeight: 22px;
$iconSize: 24px;

.DetailsRow {
	display: flex;

	.Divider + & {
		margin-top: $labelMargin + $dividerMargin;
	}

	+ .DetailsRow {
		margin-top: $labelMargin;
	}

	.DetailsRowLabel {
		flex: 0 0 175px;
		min-height: $labelHeight;
		display: inline-block;
		color: var(--theme-color-grey-text);
	}

	.DetailsRowValue {
		flex: 1 1;
		display: inline-block;

		> .IconBoolean {
			// support for boolean value
			margin-top: $iconSize - ($labelMargin + $labelHeight);
		}

		&.margin-top-neg-badge {
			margin-top: -2px;
		}
		&.margin-top-neg-svg {
			//margin-top: -6px;
			margin-top: $iconSize - ($labelMargin + $labelHeight);
		}
	}
}
