@import "../../../assets/scss/helpers";

.Badge {
	@include default-font-mono();

	display: inline-block;
	padding: 4px 8px;
	font-size: 14px;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;

	&.pill {
		border-radius: 50%;
	}
}
