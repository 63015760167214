@use "sass:math";

.InternalLinkIconButton {
	position: relative;

	vertical-align: middle;
	user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;

	display: inline-flex;
	align-items: center;
	justify-content: center;

	a {
		//TODO issue with colors, blue, purple, red for regular/visited/active...
		text-decoration: none;
	}
	
	> svg {
		position: relative;
		//z-index: 10;
	}

	> .button-effects {
		pointer-events: none;
		position: absolute;
		//background-color: grey;
		//z-index: 5;
		border-radius: 50%;
	}

	@mixin size($iconSize, $effectSize) {
		//height: $effectSize;
		//width: $effectSize;
		> svg {
			height: $iconSize;
			width: $iconSize;
		}
		> .button-effects {
			left: calc(50% - #{math.div($effectSize, 2)});
			top: calc(50% - #{math.div($effectSize, 2)});
			height: $effectSize;
			width: $effectSize;
		}
	}

	&.size-s {
		@include size(20px, 32px);
	}

	&.size-m {
		@include size(24px, 36px);
	}

	&.enabled {
		cursor: pointer;

		&:hover, &.hover {
			.button-effects {
				//TODO could use a --current version
				background-color: var(--theme-icon-button-hover-bg, var(--theme-effect-hover-bg));
			}
		}

		&:active, &.active {
			.button-effects {
				//TODO could use a --current version
				background-color: var(--theme-icon-button-active-bg, var(--theme-effect-active-bg));
			}
		}
	}

	&.disabled {
		cursor: default;
		color: var(--theme-icon-button-disabled-color, var(--theme-effect-disabled-color));
	}
}
