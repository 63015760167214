@import "/src/assets/scss/helpers";

.Priority {
	display: flex;

	&.priority-highest {
		color: var(--theme-color-red-text);
	}
	&.priority-high {
		color: var(--theme-color-orange-text);
	}
	&.priority-medium {
		color: var(--theme-color-green-text);
	}
	&.priority-low {
		color: var(--theme-color-blue-text);
	}
	&.priority-lowest {
		color: var(--theme-color-grey-text);
	}
	&.priority-unknown {
		color: var(--theme-color-purple-text);
	}

	svg {
		font-size: 24px;
		vertical-align: middle;
	}
}
